@import url('https://fonts.googleapis.com/css2?&family=Secular+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    scroll-behavior: smooth;
    font-size: 16px;
}

:root {
    ---primary: #fff;
    ---secondary: #40A3D6;
    ---tertiary: #000;
    ---fourth: #f2f2f2;
    ---fifth: #4CAF50;
    ---error: red;
    ---shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08), 0 4px 6px -2px rgba(71, 63, 79, 0.16);
    ---lightshadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}

body {
    width: 100%;
    background-color: var(---primary);
    color: var(---tertiary);
}

.primary {
    color: var(---primary);
}

.secondary {
    color: var(---secondary);
}

.tertiary {
    color: var(---tertiary);
}

.fourth {
    color: var(---fourth);
}

.fifth {
    color: var(---fifth);
}

.error {
    color: var(---error);
}

header {
    box-shadow: var(---lightshadow);
    color: var(---tertiary);
}

.content {
    max-width: 90%;
    margin: auto;
    padding: 3rem 20px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}
